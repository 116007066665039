import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";
import ReactPlayer from "react-player";
import Video from "react-player/vimeo";

type Props = React.HTMLAttributes<HTMLDivElement> & {
	videoId: string;
	fitContent?: boolean;
};

const VideoPlayer: React.FC<Props> = ({ videoId, className, ...props }) => {
	const [videoRatio, setVideoRatio] = useState(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const playerRef = useRef<Video>(null);
	const isVisible = useIsVisible(containerRef);
	const [playerOffset, setPlayerOffset] = useState({
		width: 0,
		height: 0,
		top: 0,
		left: 0,
	});

	const onResize = useCallback(async () => {
		if (!videoRatio) return;
		const cw = containerRef.current?.offsetWidth || 0;
		const ch = containerRef.current?.offsetHeight || 0;
		const containerRatio = cw / ch;
		if (!props.fitContent && videoRatio < containerRatio) {
			const vh = cw / videoRatio;
			setPlayerOffset({
				width: cw,
				height: vh,
				top: ((vh - ch) / 2) * -1,
				left: 0,
			});
		} else {
			const vw = ch * videoRatio;
			setPlayerOffset({
				width: vw,
				height: ch,
				top: 0,
				left: ((vw - cw) / 2) * -1,
			});
		}
	}, [videoRatio, containerRef]);

	const onReady = useCallback(async (player: ReactPlayer) => {
		const w = await player.getInternalPlayer().getVideoWidth();
		const h = await player.getInternalPlayer().getVideoHeight();
		setVideoRatio(w / h);
	}, []);

	useEffect(() => {
		addEventListener("resize", onResize);
		onResize();
		return () => removeEventListener("resize", onResize);
	}, [videoRatio]);

	return (
		<div
			ref={containerRef}
			className={cx("relative overflow-hidden bg-gray-100", className)}
			{...props}
		>
			<Video
				ref={playerRef}
				url={`https://vimeo.com/${videoId}`}
				width={playerOffset.width}
				height={playerOffset.height}
				onReady={onReady}
				style={playerOffset}
				className="absolute"
				config={{
					playerOptions: {
						responsive: true,
						autopause: true,
						playsinline: true,
					},
				}}
				playing={isVisible}
				muted
				loop
			/>
		</div>
	);
};

export default VideoPlayer;
